<template>
  <teleport to="body">
    <div class="invoice-red-package">
      <div class="toast"></div>
      <div class="red-package-content">
        <!-- <img class="close" @click="close" src="@/assets/img/close.png" alt="">
         <img @click.stop="onOpenPackage" :src="src" alt="" /> -->
          <h1 class="lottery-text">
              <span style="position: relative;top: 0.68rem;">{{hint}}<span id="Message">{{awardName}}</span></span>
              <img id="try-winnerIMG" :src="src" alt="获奖图片" />
          </h1>
          <a href="javaScript:void(0);"><img src="@/assets/img/lottery/bg34.png" style="width: 5.5rem" @click="close"></a>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "RedPackage",
  emits:['close'],
  props: {
    src: {
      type: String,
      default: "",
    },
    awardName: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
    
    target: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, ctx) {
    const router = useRouter();
    function onOpenPackage() {
      // window.location.href = 'http://m.bzwz.com/lottery'
      // router.push({
      //   name: props.target,
      //   query: props.params,
      // });
      ctx.emit('close')
    }
    function close() {
       ctx.emit('close')
    }
    return {
      onOpenPackage,
      close
    };
  },
});
</script>


<style lang="scss" scoped>
.invoice-red-package {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  .toast {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .red-package-content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    img{
      width: 100%;
    }
    .close{
      width: 32px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(400%);
      z-index: 100;
    }
  }
}


.lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#try-winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.6rem;
    right: 3.25rem;
}
</style> 